import React, { useContext, useRef, useState } from 'react';
import { PlanCategory } from '../PlanCard';
import PaymentsModal from './PaymentsModal';

interface Context {
  show: (options?: PaymentProps) => void;
  close: () => void;
}

const PaymentsModalContext = React.createContext<Context>({
  show: () => {},
  close: () => {}
});

interface PaymentProps {
  notificationText?: string;
  defaultCategory: PlanCategory;
}

export function PaymentsModalProvider({ children }: React.PropsWithChildren<{}>) {
  const dialogRef = useRef<any>(null);
  const [dialogProps, setDialogProps] = useState<undefined | PaymentProps>();
  const [value] = useState<Context>(() => ({
    show: options => {
      setDialogProps(options);
      dialogRef.current.show();
    },
    close: () => {
      dialogRef.current.hide();
    }
  }));

  return (
    <PaymentsModalContext.Provider value={value}>
      <PaymentsModal forwardRef={dialogRef} {...dialogProps} />
      {children}
    </PaymentsModalContext.Provider>
  );
}

export function usePaymentsModal() {
  return useContext(PaymentsModalContext);
}
